







































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Role from '@/store/entities/admin/role';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';
import RoleOptions from './role-options.vue';
import Permissions from './permissions.vue';

class PageRoleRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView, RoleOptions, Permissions }
})
export default class Roles extends ListBase {
    pagerequest: PageRoleRequest = new PageRoleRequest();
    role: Role = new Role();
    tabModel: any = null;
    modalSetting: any = {
        entity: "role",
        title: 'Rol',
        width: "400px"
    };

    get exluddeIds() {

        return { edit: [1,2], delete: [1,2] };
    }

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }

    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }

    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true })
    ]
    columns = [
        this.gCol('name', 'Rol', 'text'),
        this.gCol('user_numbers', 'Numero de Usuarios', 'text'),
        this.defaultActions()
    ]
    async created() {
    }
}
